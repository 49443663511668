// Import Node Modules
import React from 'react';

// renderPage
import { renderPage } from '../template/RenderPage';

// TempData
import Page1data from '../mocks/pages/PageData/page1';

// Class
const IndexPage = () => <div>{renderPage(Page1data)}</div>;

export default IndexPage;
