// Import Image for Banner Component
import BannerImage from '../../../assets/Images/CR_lokation_10.png';
import BannerImageMobile from '../../../assets/Images/bannerMobile.png';
import animation from '../../../assets/Images/Afkast.json';

// Import Image for ImageCard_Collapse
import nytjob from '../../../assets/Images/image-nytjob.png';
import syg from '../../../assets/Images/image-syg.png';

// Import Icons
import chatIcon from '../../../assets/Icons/png/sticky_chat_black.png';

// Import SlideShow Images
import kundeservice from '../../../assets/Images/image-kundeservice.png';

// Import PromoteApp Image
import Promote from '../../../assets/Images/Promote_app.png';

// Color Variables
import * as ColorGuide from '../../../cssGuides/colorGuide';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const Page1data = {
  pageName: 'Page1',
  pageTemplate: 'Template1',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'Sampension',
    ogData: {
      image: BannerImage,
    },
    pageDescription: 'Sampensions web site',
    disableScroll: false,
    bottomContent: null,
    desktopTools: {
      chat: {
        text: 'Chat',
        offlineText: 'Offline',
      },
      ring: {
        text: 'Ring til os',
        info:
          '<h3 class="text-center">Kunderådgivning:</h3><a href="http://www.google.com" target="_blank"><h1 class="text-center">77 55 62 50</h1></a><p class="text-center">Mandag-torsdag: 8.30-21.00<br />Fredag: 8.30-16.00</p>',
      },
      bestil: {
        text: 'Skriv til os',
        url: '/page31',
      },
      serviceMessage: {
        text:
          '<p><strong>Åbningstider i julen</strong></p><p>Mellem jul og nytår holder vi åbent den 28. og 29. december. Begge dage er telefonerne åbne 10:00-16:00</p>',
        start: '2022-01-07T10:00:00',
        end: '2022-01-29T16:00:00',
      },
    },
    cookieBanner: {
      text:
        '<h3>Vores hjemmeside bruger cookies</h3><p class="body">Cookies er nødvendige for at logge ind på sampension.dk, og giver mulighed for at optimere indholdet, så det passer til dit behov.<br />Ved at klikke OK accepterer du vores brug af cookies. Læs mere om, hvordan vi <a href="/forbehold/cookies">anvender cookies</a>.</p>',
    },
  },
  pageContent: [
    // Banner (Zeplin Version : 15/10-14:30)
    {
      componentName: 'Banner',
      componentData: {
        image: BannerImage,
        mobile: BannerImageMobile,

        // mobile: null,
        header: 'Saml dine pensioner<br />- og spar mange penge',
        manchet:
          'Du kan spare omkostninger, hvis du samler dine pensioner hos Sampension.<br />Det giver mere i pension.',
        url: '/test',
        urlText: 'Læs mere',
      },
    },

    // ImageCards + Collapsible (Zeplin Version : 15/10-14:30)
    {
      componentName: 'BlockArea',
      componentData: {
        backgroundColor: ColorGuide.white,
      },
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2 class="text-center">Er der sket ændringer i dit liv?</h2><p class="text-center">Vi guider dig igennem store og små begivenheder, som kan påvirke din pension</p>',
          },
        },
        {
          componentName: 'Lottie',
          componentData: {
            data: animation,
            loader: '<div>Loading</div>',
          },
        },
        {
          componentName: 'Columns',
          componentData: {
            columns: 3,
            gap: '40',
          },
          componentChildren: [
            {
              componentName: 'ImageCard',
              componentData: {
                id: 1,
                image: syg,
                url: '',
                manchet: 'Det skal du vide',
                text: 'Hvis du bliver syg',
              },
            },
            {
              componentName: 'ImageCard',
              componentData: {
                id: 2,
                image: '',
                url: '',
                manchet: 'Optimer din udbetaling',
                text: 'Snart på pension?',
              },
            },
            {
              componentName: 'ImageCard',
              componentData: {
                id: 3,
                image: nytjob,
                url: '',
                manchet: 'Dine muligheder',
                text: 'Nyt job eller fratrædelse?',
              },
            },
          ],
        },
        {
          componentName: 'Collapsible',
          componentData: {
            titleOn: 'Færre livsændringer',
            titleOff: 'Flere livsændringer',
          },
          componentChildren: [
            {
              componentName: 'Columns',
              componentData: {
                columns: 3,
                gap: '40',
              },
              componentChildren: [
                {
                  componentName: 'IfMobileHide',
                  componentData: [],
                  componentChildren: {
                    componentName: 'HtmlRender',
                    componentData: {
                      html: '<h2 class="text-center">Livsændringer</h2>',
                    },
                  },
                },
                {
                  componentName: 'DivContainer',
                  componentData: [],
                  componentChildren: [
                    {
                      componentName: 'Goto',
                      componentData: {
                        text: 'Fratrådt eller har fået nyt job',
                        url: '/item1',
                      },
                    },
                    {
                      componentName: 'Goto',
                      componentData: {
                        text: 'Fratrådt eller har fået nyt job',
                        url: '/item1',
                      },
                    },
                    {
                      componentName: 'Goto',
                      componentData: {
                        text: 'Fratrådt eller har fået nyt job',
                        url: '/item1',
                      },
                    },
                  ],
                },
                {
                  componentName: 'DivContainer',
                  componentData: [],
                  componentChildren: [
                    {
                      componentName: 'Goto',
                      componentData: {
                        text: 'Fratrådt eller har fået nyt job',
                        url: '/item1',
                      },
                    },
                    {
                      componentName: 'Goto',
                      componentData: {
                        text: 'Fratrådt eller har fået nyt job',
                        url: '/item1',
                      },
                    },
                    {
                      componentName: 'Goto',
                      componentData: {
                        text: 'Fratrådt eller har fået nyt job',
                        url: '/item1',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    // IconCards (Zeplin Version : 15/10-14:30)
    {
      componentName: 'BlockArea',
      componentData: {
        backgroundColor: ColorGuide.aquaLight,
      },
      componentChildren: [
        {
          componentName: 'Columns',
          componentData: {
            columns: 2,
            gap: '40',
            minWidth: '1024px',
          },
          componentChildren: [
            {
              componentName: 'IconCard',
              componentData: {
                id: 1,
                icon: chatIcon,
                header: 'Få overblik over din pension',
                text:
                  'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
                url: '/item1',
              },
            },
            {
              componentName: 'IconCard',
              componentData: {
                id: 1,
                icon: chatIcon,
                header: 'Få overblik over din pension',
                text:
                  'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
                url: '/item1',
              },
            },
          ],
        },
      ],
    },

    // Email Signup (Zeplin Version : 15/10-14:30)
    {
      componentName: 'SignupEmail',
      componentData: {
        header: 'Hold dig opdateret',
        manchet:
          'Giv os din e-mail - og få besked når der er nyt om din ordning',
        placeholder: 'din@email.dk',
        btnText: 'Tilmeld',
      },
    },

    // SLideshow (Zeplin Version : 15/10-14:30)
    {
      componentName: 'SlideShow',
      componentData: {
        header: 'Aktuelle begivenheder',
        manchet: 'Nyheder og updates indenfor pension og forsikring',
      },
      componentChildren: [
        {
          componentName: 'Slider',
          componentData: {
            id: 1,
            source: kundeservice,
            type: 'nyhed 1',
            header: 'Laveste antal klager nogensinde hos Sampension',
            manchet:
              'Antallet af kundeklager i Sampension-fællesskabet var sidste år det laveste nogensinde. Det skyldes blandt andet et målrettet arbejde med at tage ved lære af klagesager, forklarer sunheds- og skadeschef.',
            url: '/test:nyhed_',
          },
        },
        {
          componentName: 'Slider',
          componentData: {
            id: 2,
            source: nytjob,
            type: 'nyhed 2',
            header: 'Laveste antal klager nogensinde hos Sampension',
            manchet:
              'Antallet af kundeklager i Sampension-fællesskabet var sidste år det laveste nogensinde. Det skyldes blandt andet et målrettet arbejde med at tage ved lære af klagesager, forklarer sunheds- og skadeschef.',
            url: '/test:nyhed_',
          },
        },
        {
          componentName: 'Slider',
          componentData: {
            id: 3,
            source: kundeservice,
            type: 'nyhed 3',
            header: 'Laveste antal klager nogensinde hos Sampension',
            manchet:
              'Antallet af kundeklager i Sampension-fællesskabet var sidste år det laveste nogensinde. Det skyldes blandt andet et målrettet arbejde med at tage ved lære af klagesager, forklarer sunheds- og skadeschef. Antallet af kundeklager i Sampension-fællesskabet var sidste år det laveste nogensinde. Det skyldes blandt andet et målrettet arbejde med at tage ved lære af klagesager, forklarer sunheds- og skadeschef.',
            url: '/test:nyhed_',
          },
        },
      ],
    },
    {
      componentName: 'BlockArea',
      componentData: {
        backgroundColor: ColorGuide.aqua,
      },
      componentChildren: [
        {
          componentName: 'Columns',
          componentData: {
            columns: 2,
            gap: '40',
          },
          componentChildren: [
            {
              componentName: 'InsertImage',
              componentData: {
                source: Promote,
                imageAltText: 'App on mobile',
              },
            },

            {
              componentName: 'DivContainer',
              componentData: [],
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<h2>Enkelt pensions- <br />& forsikringsoverblik</h2><p class="intro">Vi lancerer snart et helt nyt værktøj under navnet pensionsappen, her på sampension.dk & som iOS og Android app.</p>',
                  },
                },
                {
                  componentName: 'PromoteApp',
                  componentData: {},
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

// Exports
export default Page1data;
